<!--
 * @Author: jiang
 * @Date: 2021-07-12 09:34:19
 * @Description: 流程、要点、制度; 部门管理员有侧边栏，其他用户查询自己部门下的
-->

<template>

  <el-container class="g-page-list" style="height: 100%; padding: 0 15px 15px; overflow: hidden;">


    <el-aside class="g-bg g-mr-15"
      v-if="userSuperId === userId || userId==userDataId || userId==188||userId==userData2Id|| userId==7||userId==998||userId==1269">
      <el-scrollbar y view-style="padding: 10px;">
        <dept-tree @change="onDeptChange"></dept-tree>
      </el-scrollbar>
    </el-aside>


    <el-aside class="g-bg g-mr-10">
      <el-scrollbar y view-style="padding: 10px;">
        <index-tree @change="onIndexChange"></index-tree>
      </el-scrollbar>
    </el-aside>

    <!-- 核心 main-->
    <el-main class="g-bg">



      <div v-show="currentIndexName && currentIndex" style="padding: 8px 16px;
    background-color: #06a37e;
    color: #fff;" v-if="0">
        <h3>{{currentIndexName}}-情况综述：</h3>
        <el-input type="textarea" :autosize="{ minRows: 3}" v-model="content" style="margin-bottom: 10px;"
          placeholder="请填写该栏目情况综述"></el-input>
        <el-button type="success" @click="saveGo">保存提交</el-button>


      </div>





      <el-scrollbar y view-style="padding: 15px;">


        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :label="currentIndexName+'目录明细'" name="first">
            <br />
            <el-form ref="form" inline :model="searchData" label-position="top">
              <el-form-item label="" prop="title">
                <el-input v-model="searchData.title" placeholder="请输入标题关键词" clearable></el-input>
              </el-form-item>
              <!-- <el-form-item
              label="只看由我创建的"
              prop="user_id"
            >
              <el-select v-model="searchData.user_id">
                <el-option
                  label="是"
                  :value="userId"
                ></el-option>
                <el-option
                  label="否"
                  value=""
                ></el-option>
              </el-select>
            </el-form-item> -->
              <el-form-item style="vertical-align: bottom;">
                <c-btn-search @search="onRefresh" @reset="onReset" @create="onCreate"></c-btn-search>
              </el-form-item>
            </el-form>

            <el-table :data="tableData">

              <el-table-column label="所在类目" prop="index_category.name" width="220px" sortable />
              <el-table-column label="基础数据标题" prop="title" show-overflow-tooltip sortable />

              <el-table-column label="负责部门" prop="department.name" width="200px" />
            <!--  <el-table-column label="审核人" prop="user.name" width="200px">
                <template slot-scope="{ row }">

                  <span
                    v-if="row.user.department.name!=row.department.name">{{row.user.department.name}}({{row.user.name}})</span>
                  <span v-else>{{row.user.name}}</span>
userSuperId === userId || userId==userDataId || userId==188||userId==userData2Id|| userId==7||userId==998||userId==1269
                </template>
              </el-table-column> -->
              <el-table-column label="更新时间" sortable prop="created_at" width="130"></el-table-column>
              <el-table-column label="操作" align="center" width="160px">
                <template slot-scope="{ row }">
                  <c-btn-table :show="['info', (row.user_id === userId||row.user_id==userData2Id|| row.user_id==7||row.user_id==998||row.user_id==1269) ? 'delete' : '']" @info="onInfo(row)"
                    @delete="onDelete(row)"></c-btn-table>
                </template>
              </el-table-column>
            </el-table>

            <b-pagination :page="page" @change="fetchList"></b-pagination>

          </el-tab-pane>
          <el-tab-pane label="指标项" name="second" v-if="0">
            <!--  -->

            <el-table :data="tableData2" style="width: 100%" border>
              <el-table-column label="序号" type="index" width="55" align="center">
              </el-table-column>
              <el-table-column label="指标名称" prop="date" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.isShow">{{ scope.row.name }}</span>
                  <el-input type="text" :placeholder="scope.row.name" v-model="scope.row.name"
                    v-show="!scope.row.isShow" />
                </template>
              </el-table-column>
              <el-table-column label="指标值" prop="value" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.isShow">{{ scope.row.value }}</span>
                  <el-input type="text" :placeholder="scope.row.value" v-model="scope.row.value"
                    v-show="!scope.row.isShow" />
                </template>
              </el-table-column>
              <el-table-column label="单位" prop="unit" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.isShow">{{ scope.row.unit }}</span>
                  <el-input type="text" :placeholder="scope.row.unit" v-model="scope.row.unit"
                    v-show="!scope.row.isShow" />
                </template>
              </el-table-column>
              <el-table-column label="年份" prop="dim_time" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.isShow">{{ scope.row.dim_time }}</span>
                  <el-input type="text" :placeholder="scope.row.dim_time" v-model="scope.row.dim_time"
                    v-show="!scope.row.isShow" />
                </template>
              </el-table-column>
              <el-table-column label="范围" prop="dim_time" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.isShow">{{ scope.row.dim_area }}</span>
                  <el-input type="text" :placeholder="scope.row.dim_area" v-model="scope.row.dim_area"
                    v-show="!scope.row.isShow" />
                </template>
              </el-table-column>
              <el-table-column align="right">
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                    v-show="scope.row.isShow">编辑</el-button>
                  <el-button @click="hold(scope.$index, scope.row)" size="mini"
                    v-show="!scope.row.isShow">保存</el-button>
                  <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                  <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                    v-show="scope.row.isShow">查看下级</el-button>
                </template>
              </el-table-column>
            </el-table>

          </el-tab-pane>
        </el-tabs>

<!--
            <el-button type="primary" icon="el-icon-edit"  @click="addLine()">新增指标</el-button> -->

      </el-scrollbar>

      <b-dialog ref="detailDialog" width="800px" title="新建">
        <detail-view v-if="bodyShow" :data="formData" :type="currentType" @success="onDetailSuccess"></detail-view>
      </b-dialog>

      <b-dialog ref="infoDialog" width="800px" title="详情">
        <info-view :id="currentRow.id" :type="currentIndexName"></info-view>
      </b-dialog>
    </el-main>
  </el-container>
</template>

<script>
  import DeptTree from '@/components/tree/dept.vue'
  import IndexTree from '@/components/tree/index-catelog.vue'
  import DetailView from './list-detail2.vue'
  import InfoView from './list-info2.vue'
  import {
    // createRecods,
    getRecodsPage,
    deleteRecods,
  } from '@/api/manager'

  import {
    UpdateIndexCategoryConent
  } from '@/api/special/IndexCategory.js'


  export default {
    components: {
      DeptTree,
      DetailView,
      IndexTree,
      InfoView,
    },
    data() {
      return {
        tableData2: [
          // {
          //   name: "指标名称",
          //   value :"指标值",
          //   unit:"万元",
          //   dim_time:"生效年份",
          //   dim_area:"全区",
          //   dim_other:"",
          //   isShow: true,
          // },
        ],
        activeName: 'first',
        userSuperId: +process.env.VUE_APP_SUPER_USER_ID,
        userDataId: +process.env.VUE_APP_DATA_USER_ID,
        userData2Id: +process.env.VUE_APP_DATA_USER2_ID,
        searchData: {
          title: '',
          user_id: '',
        },
        bodyShow: false,
        page: {
          current: 1,
          size: 15,
          total: 0,
        },
        tableData: [],
        formData: {},
        currentRow: {},
        currentType: '',
        currentDept: '',
        currentIndex: '',
        currentIndexName: "",
        content: ""
      }
    },
    computed: {
      userId() {
        return this.$store.getters['user/id']
      },
      userDepartmentId() {
        console.log(this.$store.state.user.userInfo)
        return this.$store.state.user.userInfo.department.id
      },
    },
    watch: {
      '$route.path': {
        handler(path) {
          console.log(path)
          const typeMap = {
            '/manager/attention': '要点',
            '/manager/flow': '流程',
            '/manager/system': '制度',
            '/manager/data': '工作基础数据',
          }
          this.currentType = typeMap[path]
          this.fetchList()
        },
        immediate: true,
      },
    },
    methods: {
       /* 编辑 */
          handleEdit(index, row) {
            console.log(index, row);
            row.isShow = false;
          },
          /* 保存 */
          hold(index, row) {
            row.isShow = true;
          },
          /* 删除 */
          handleDelete(index, row) {
            console.log(index, row);
            this.tableData2.splice(index, 1);
          },
          /* 添加新的一行 (默认是可编辑状态)*/
          addLine() {
            let obj = {
              date: "",
              name: "",
              address: "",
              isShow: false,
            };
            this.tableData2.push(obj);
          },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      saveGo() {
        UpdateIndexCategoryConent(this.currentIndex, {
          content: this.content
        }).then(res => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        });
      },
      fetchList() {
        const params = {
          title: this.searchData.title,
          type: this.currentType,
          user_id: this.searchData.user_id,
          department_id: this.currentDept,
          current_page: this.page.current,
          current_count: this.page.size,
          index_category_id: this.currentIndex
        }

        let departmentId = null
        if((this.userSuperId == this.userId) ||this.userId==7||this.userId==998  ||this.userId==1269||userId==110){
           params.department_id = ""
        }else{
          params.department_id=this.userDepartmentId
        }

        if(this.currentDept){
          params.department_id=this.currentDept
        }

        getRecodsPage(params).then(res => {
          this.tableData = res.data
          this.page.total = res.total
        })
      },
      onRefresh() {
        this.page.current = 1
        this.fetchList()
      },
      onReset() {
        this.$refs.form.resetFields()
        this.currentIndex = ""
        this.currentIndexName = ""
        this.onRefresh()
      },
      onDeptChange(id) {
        this.currentDept = id
        this.onRefresh()
      },
      onIndexChange(data) {
        this.currentIndex = data.id
        this.currentIndexName = data.name
        this.content = data.content
        this.onRefresh()
      },
      onCreate() {
        this.bodyShow = true
        let departmentId = null
        if(this.userSuperId === this.userId || this.userId==this.userDataId || this.userId==188||this.userId==this.userData2Id|| this.userId==7||this.userId==998||this.userId==1269||userId==110){
          departmentId=null
        }else{
          departmentId=this.userDepartmentId
        }

        if(this.currentDept){
          departmentId=this.currentDept
        }


        this.formData = {
          title: '',
          department_id: departmentId,
          order: 0,
          content: '',
          index_category_id: this.currentIndex || null,
          currentIndexName: this.currentIndexName || "",
          media_keys: [],
        }
        this.$refs.detailDialog.open()
      },
      onDetailSuccess() {
        this.$refs.detailDialog.close()
        this.bodyShow = false
        this.onReset()
      },
      onInfo(row) {
        this.currentRow = row
        this.$refs.infoDialog.open()
      },
      onDelete(row) {
        this.$confirm('确定要删除当前项吗？', {
            type: 'warning'
          })
          .then(() => {
            return deleteRecods(row.id)
          })
          .then(() => {
            this.$message.success('删除成功')
            this.onRefresh()
          })
          .catch(() => {})
      },
    },
  }
</script>


<style>

  /* 除去input的边框 */
  /* ::v-deep .el-input__inner {
    border: none !important;
  } */
  </style>
